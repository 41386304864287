<template>
  <div id="addIncidence" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">AGREGAR INCIDENCIA</v-row>
      <v-container fluid class="addForms" style="margin-top: 2% !important">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol formularios">
            <v-row>
              <div class="form-group">
                <label for="job">Solicitante</label>
                <input
                  type="text"
                  name="solicitante"
                  id="solicitante"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="persona"
                  disabled
                  maxlength="20"
                  v-if="role == 'Basico'"
                />
                <select
                  v-else
                  name="solicitante"
                  id="solicitante"
                  v-model="person"
                >
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="emp in employees"
                    :key="emp.id"
                    v-bind:value="emp.id"
                  >
                    {{
                      emp.nombre +
                      " " +
                      emp.apellidoPaterno +
                      " " +
                      emp.apellidoMaterno
                    }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group">
                <label for="initialDate">Fecha Ausentismo</label>
                <input
                  type="date"
                  name="initialDate"
                  id="initialDate"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="initialDate"
                  required
                  maxlength="20"
                />
              </div>
              <div class="form-group">
                <label for="finishDate">Fecha Reincorp</label>
                <input
                  type="date"
                  name="finishDate"
                  id="finishDate"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="finishDate"
                  required
                  maxlength="20"
                />
              </div>
              <div class="form-group">
                <label for="via">Comunicación</label>
                <select name="via" id="via" v-model="via">
                  <option disabled value="0">Selecciona vía</option>
                  <option
                    v-for="via in vias"
                    :key="via.id"
                    v-bind:value="via.id"
                  >
                    {{ via.viaComunicacion }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="causa">Causa</label>
                <select name="causa" id="causa" v-model="causa">
                  <option disabled value="0">Selecciona causa</option>
                  <option
                    v-for="causa in causas"
                    :key="causa.id"
                    v-bind:value="causa.id"
                  >
                    {{ causa.causa }}
                  </option>
                </select>
              </div>
              <!--Justificante-->
              <div class="form-group">
                <label for="just">Justificante</label>
                <input
                  type="file"
                  name="just"
                  id="just"
                  class="inputs"
                  @change="onFileChange($event)"
                  accept="image/*"
                />
              </div>
              <!--Justificante-->
              <div class="form-group">
                <label for="just1">Justificante</label>
                <input
                  type="file"
                  name="just1"
                  id="just1"
                  class="inputs"
                  @change="onFileChange1($event)"
                  accept="image/*"
                />
              </div>
            </v-row>
            <v-row align="start" justify="start">
              <div class="form-group">
                <label for="coment" class="">Comentario</label>
                <textarea
                  id="coment"
                  placeholder="Editor de texto..."
                  v-model="coment"
                  @keypress="alfaNumerico($event)"
                  maxlength="200"
                  class="mt-1"
                ></textarea>
              </div>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
            class="mt-5"
            align-self="center"
          >
            <v-row justify="center">
              <button class="botonAmarillo" @click="regresar()">
                Cancelar
              </button>
              <button class="botonAmarilloDerecho" @click="validacion()">
                Guardar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            {{ respuesta }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="cancelar()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
      dialog: false,
      selectedItem: null,
      initialDate: "",
      finishDate: "",
      //dialogs de empleado
      errors: [],
      error: false,
      respuesta: "",
      confirmation: false,
      advice: false,
      //Data CFDI
      coment: "",
      causas: [],
      causa: 0,
      via: 0,
      vias: [],
      message: "",
      persona: localStorage.nombreEmpleadoGlobal,
      person: localStorage.empleadoIdGlobal,
      url: "",
      url2: "",
      justificante2: "",
      justificante1: "",
      documentos: [],
      role: localStorage.roles,
      employees: [],
    };
  },
  methods: {
    listarVias() {
      this.show = true;
      axios
        .get(Server + "/ausentismosLaborales/catalogo-vias", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.vias = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarCausas() {
      this.show = true;
      axios
        .get(Server + "/ausentismosLaborales/catalogo-causas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.causas = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    regresar() {
      this.$router.push("/incidences");
    },
    guardar() {
      this.advice = false;
      this.justificante1 = sessionStorage.getItem("base64img1");
      this.justificante2 = sessionStorage.getItem("base64img2");

      if (this.justificante1 != null && this.justificante1 != "") {
        var indexCorte = this.justificante1.indexOf(",");
        this.justificante1 = this.justificante1.substring(indexCorte + 1);
        this.documentos.push({
          Imagen: this.justificante1,
          TipoDocumento: parseInt(20),
        });
      }

      if (this.justificante2 != null && this.justificante2 != "") {
        var indexCorte = this.justificante2.indexOf(",");
        this.justificante2 = this.justificante2.substring(indexCorte + 1);
        this.documentos.push({
          Imagen: this.justificante2,
          TipoDocumento: parseInt(20),
        });
      }

      console.log("documentos pasados", this.documentos);
      this.show = true;
      axios
        .post(
          Server + "/ausentismosLaborales",
          {
            EmpleadoId: this.person,
            FechaAusentismoInicio: this.initialDate,
            FechaReincorporacion: this.finishDate,
            ViaComunicacionId: parseInt(this.via),
            Comentario: this.coment,
            CausaAusentismoId: parseInt(this.causa),
            Documentos: this.documentos.length > 0 ? this.documentos : null,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.initialDate = "";
          this.finishDate = "";
          this.via = 0;
          this.coment = "";
          this.causa = 0;
          this.documentos = [];
          this.respuesta = "La incidencia fue guardada con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.initialDate = "";
            this.finishDate = "";
            this.via = 0;
            this.coment = "";
            this.causa = 0;
            this.documentos = [];
            this.respuesta = "La incidencia no fue almacenada";
            this.confirmation = true;
          }
        });
    },
    aux() {
      this.advice = false;
      this.justificante2 = "";
      this.justificante1 = "";
      this.url = "";
      this.url2 = "";
      this.coment = "";
      this.causa = 0;
      this.via = 0;
      this.initialDate = "";
      this.finishDate = "";
      this.documentos = [];
    },
    cancelar() {
      this.confirmation = false;
      this.justificante2 = "";
      this.justificante1 = "";
      this.url = "";
      this.url2 = "";
      this.coment = "";
      this.causa = 0;
      this.via = 0;
      this.initialDate = "";
      this.finishDate = "";
      this.documentos = [];
      this.$router.push("/incidences");
    },
    validacion() {
      this.errors = [];
      if (this.initialDate == "") {
        this.errors.push("Se debe especificar la fecha de inicio.");
      }
      if (this.finishDate == "") {
        this.errors.push("Se debe especificar la fecha de reincorporación.");
      }
      if (this.via == 0) {
        this.errors.push("Se debe especificar la vía de comunicación.");
      }
      if (this.causa == 0) {
        this.errors.push("Se debe especificar la causa.");
      }
      if (this.coment == "") {
        this.errors.push("Favor de escribir un comentario.");
      }
      if (this.errors.length == 0) {
        this.message = "Aviso";
        this.respuesta = "¿Esta seguro de agregar la incidencia?";
        return (this.advice = true);
      } else {
        this.message = "Incidencia";
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    onFileChange(evt) {
      const file = evt.target.files[0];
      this.url = URL.createObjectURL(file);
      var fileBase = evt.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        sessionStorage.setItem("base64img1", reader.result);
      };
      reader.readAsDataURL(fileBase);
      this.justificante1 = event.target.files[0];
      //console.log("just1", this.justificante1);
    },
    onFileChange1(evt) {
      const file = evt.target.files[0];
      this.url2 = URL.createObjectURL(file);
      var fileBase = evt.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        sessionStorage.setItem("base64img2", reader.result);
      };
      reader.readAsDataURL(fileBase);
      this.justificante2 = event.target.files[0];
      //console.log("just2", this.justificante2);
    },
    inicializar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.listarCausas();
      this.listarVias();
    },
    obtenerEmpleados() {
      this.show = true;
      axios
        .get(Server + "/empleados/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.employees = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log("Error", e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    sessionStorage.clear();
    this.obtenerEmpleados();
    this.inicializar();
  },
};
</script>